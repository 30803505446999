@import '../../../assets/css/colors';
@import '../../../assets/css/variables';
@import '../../../assets/css/text.module';


.logoContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.logoWrapper {
    display: flex;
    width: 56px;
    height: 56px;
    cursor: pointer;
    border: 2px solid $gray;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.logo {
    width: 48px;
    height: 48px;
}

.upload {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 56px;
    cursor: pointer;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: $font-family-lato;
}

.upload input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.uploadLogo {
    width: 24px;
    height: 24px;
}

.form {
    width: 100%;
    flex-direction: row !important;
    flex-wrap: nowrap;
}

.radioGroup {
    width: 100%;
    padding: 0 5% 0 20%;
    flex-wrap: nowrap !important;
}

.checkboxWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    span {
        font-size: $normalSize;
    }
}
