@import '../../assets/css/colors';

.container {
    display: flex;
    position: relative;
    flex-direction: row;
    background-color: $lightblue;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    padding: 4px 4px 4px 16px;
    color: $white;
    z-index: 101;
    cursor: pointer;
}

.iconDown {
    rotate: 270deg;
}

.iconUp {
    rotate: 90deg;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
}

.box {
    position: relative;
    background-color: $lightgray;
    margin: 0 4px 0 4px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 16px;
}