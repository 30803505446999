@import '../../assets/css/variables';
@import '../../assets/css/colors';

.container {
    display: flex;
    height: 80px;
    background-color: $lightblue;
    border-bottom: 1px solid $blue;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
}

.titleWrapper {
    margin-right: auto;
}
