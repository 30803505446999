$darkblue: #097CB6;
$blue: #29A3E0;
$lightblue: #47B2E7;

$black: #020203;
$lightblack: #182441;

$darkpurple: #8316D6;
$purple: #A761DC;
$lightpurple: #CFB1E6;

$darkgray: #808080;
$gray: #B4B4B4;
$lightgray: #E5E4E5;

$white: #FFFFFF;

$green: #0FA632;