@import '../../../assets/css/colors';


.logoContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.logoWrapper {
    display: flex;
    width: 86px;
    height: 86px;
    cursor: pointer;
    border: 2px solid $gray;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.logo {
    width: 78px;
    height: 78px;
}
