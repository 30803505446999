@import '../../assets/css/colors';
@import '../../assets/css/variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: $white;
    width: 416px;
    padding: 8px;
    //padding: 27px 8px 8px 8px;
    border-radius: 8px;
    box-sizing: border-box;

    @media screen and (max-width: $x-small-screen) {
        width: 100%;
    }
    @media screen and (min-width: $x-small-screen) {
        width: 416px;
    }
}

.qrcodeContainer {
    display: flex;
    justify-content: center;
}

.qrcodeWrapper {
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 6px;
    padding: 4px;
    border: 6px solid rgb(35, 31, 32);
}

.qrCodeEmpty {
    width: 320px;
    height: 320px;
    opacity: 0.5;
}