@import '../../assets/css/colors';
@import '../../assets/css/variables';

.qrcodeBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: $large-screen) {
        flex-direction: column;
    }
    @media screen and (min-width: $large-screen) {
        flex-direction: row;
    }
}