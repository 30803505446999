@import '../../../assets/css/colors';
@import '../../../assets/css/variables';
@import '../../../assets/css/text.module';

.eyeContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.eyeWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.eyeIcons {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.logoWrapper {
    display: flex;
    width: 56px;
    height: 56px;
    cursor: pointer;
    border: 2px solid $gray;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.logo {
    width: 48px;
    height: 48px;
}