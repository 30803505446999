@import '../../assets/css/colors';
@import '../../assets/css/variables';
@import '../../assets/css/text.module';

.container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-self: flex-start;
    gap: 16px;

    @media screen and (max-width: $x-small-screen) {
        width: 100%;
    }
    @media screen and (min-width: $x-small-screen) {
        width: 416px;
    }
}

.countryCodeWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.phoneWrapper {
    display: flex;
    gap: 4px;
}

.options {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
}

.iconBox {
    display: flex;
    border: 2px solid $gray;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    width: 80px;
    cursor: pointer;
    flex-direction: column;
    gap: 4px;
    height: fit-content;
    padding: 4px;
}

.iconBox:hover, .selected {
    background-color: $darkgray;
    color: $white;
}

.icon {
    width: 24px;
    height: 24px;
}

.phoneInput {
    max-width: 120px !important;
    height: 41px !important;
    border: 1px solid rgb(230, 230, 230) !important;
}

.phoneDropdown {
    border: 1px solid rgb(230, 230, 230) !important;
}

.phoneContainer {
    max-width: 120px !important;
}