.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.icon {
    height: 20px;
    width: 20px;
}