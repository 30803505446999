@import './variables';

$titleSize: 24px;
$subtitleSize: 18px;
$bigSize: 16px;
$normalSize: 12px;
$smallSize: 10px;

.title {
    font-size: $titleSize;
    font-family: $font-family-lato;
}

.subtitle {
    font-size: $subtitleSize;
    font-family: $font-family-lato;
}

.bigText {
    font-size: $bigSize;
    font-family: $font-family-lato;
}

.normalText {
    font-size: $normalSize;
    font-family: $font-family-lato;
}

.smallText {
    font-size: $smallSize;
    font-family: $font-family-lato;
}