@import 'variables';
html {
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color: white;
}

body {
    font-family: $font-family-lato;
    overflow-x: hidden;
}

html,
body {
    margin: 0;
    padding: 0;
}

a {
    cursor: pointer;
    color: inherit;
}

button {
    cursor: pointer;
}
