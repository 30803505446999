.container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: -webkit-fill-available;
    gap: 4px;
}

.input {
    padding: 12px 13px;
    border-radius: 6px;
    border: 1px solid rgb(230, 230, 230)
}

.hidden {
    display: none;
}

.error, .success {
    position: absolute;
    font-size: 13px;
    color: #ff0000;
    bottom: -16px;
    text-wrap: nowrap;
}

.error {
    color: #ff0000;
}

.success {
    color: #059669;
}