@import '../../assets/css/colors';
@import '../../assets/css/variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $lightgray;
}

.bodyElement {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;
    background-color: $lightgray;
}

.faq {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    color: $lightblack;
}

.title {
    font-weight: 700;
    font-size: 16px;
}

.desc {
    font-size: 14px;
}