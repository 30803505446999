.colorContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.colorOption {
    display: flex;
    position: relative;
    gap: 8px;
}

.colorWrapper {
    width: 100px;
    height: 40px;
    align-self: flex-end;
    border-radius: 6px;
    cursor: pointer;
}

.sketchPicker {
    position: absolute;
    bottom: 40px;
    right: 0;
    z-index: 201;
}