@import '../../assets/css/variables.scss';
@import '../../assets/css/text.module.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.textArea {
    font-family: $font-family-lato;
    font-size: $normalSize;
    font-weight: 400;
    text-align: left;
    width: -webkit-fill-available;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 6px;
    padding: 12px 13px;
}

.labelContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.error, .success {
    font-size: 13px;
    text-wrap: nowrap;
}

.error {
    color: #ff0000;
}

.success {
    color: #059669;
}